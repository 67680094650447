import React from "react";
import {
  StyledBannerImageWrapper,
  StyledBannerImage,
  StyledBannerTitle,
  StyledBannerSubtitle,
  StyledBannerButton,
  
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Button from "../Button/Button";
import { StyledSpacer } from "../globalstyle";
import BackgroundImage from "gatsby-background-image";
import useScrollController from "../../hooks/useScrollController.js";

export default ({
  title,
  image,
  subtitle,
  ctaTextContact,
  ctaTextDownload,
  allImages,
}) => {
  const { scrollToCssSelector } = useScrollController();
  const imageData = allImages.find(e => e.path === image)?.childImageSharp.fluid;
  
  return (
    <StyledGridRow noMargin isFull="true">
      <StyledBannerImageWrapper>
        <StyledBannerImage>
          <BackgroundImage fluid={imageData} style={{ position: "initial" }}/>
        </StyledBannerImage>
        <StyledBannerTitle>{title}</StyledBannerTitle>
        <StyledBannerSubtitle>{subtitle}</StyledBannerSubtitle>
        <StyledBannerButton>
          <Button as="div" noPadding theme="white" to="https://www.3bee.com/documents/3Bee_apiario_aziendale.pdf">
            {ctaTextDownload}{""}
          </Button>
          <Button noPadding theme="white" 
            handleOnClick={() => scrollToCssSelector("#contactForm")}>
            {ctaTextContact}{""}
          </Button>
        </StyledBannerButton>
      </StyledBannerImageWrapper>
      <StyledSpacer size={8} />
    </StyledGridRow>
  );
};
